import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import Shipments from "./Components/Shipments/Shipments";
import GrossProfit from "./Components/GrossProfit/GrossProfit";
import SeaLCL from "./Components/SeaLCL/SeaLCL";
import SeaFCL from "./Components/SeaFCL/SeaFCL";
import Air from "./Components/Air/Air";
import Sales from "./Components/Sales/Sales";
import Table from "./Components/Table/Table";
import Overview from "./Components/Overview/Overview";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<Home />}>
          <Route path="overview" element={<Overview />} />
          <Route path="shipments" element={<Shipments />} />
          <Route path="gross-profit" element={<GrossProfit />} />
          <Route path="sea-lcl" element={<SeaLCL />} />
          <Route path="sea-fcl" element={<SeaFCL />} />
          <Route path="air" element={<Air />} />
          <Route path="sales" element={<Sales />} />
          <Route path="table" element={<Table />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
