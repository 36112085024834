import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import './Signup.css';

const schema = yup
  .object({
    username: yup.string().required("Full name is required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character"
      ),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref('password'), null], "Passwords must match"),
  })
  .required();

const Signup = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log('Signup Success:', data);
    navigate('/');
    reset();
  };

  return (
    <div className="container">
      <div className="login-signup-container">
        <div className="form-title">Signup</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input className="login-custom-input" {...register("username")} placeholder="Full Name" />
          <p className="error-message">{errors.username?.message}</p>
          <input className="login-custom-input" {...register("email")} placeholder="Email" />
          <p className="error-message">{errors.email?.message}</p>
          <input className="login-custom-input" {...register("password")} placeholder="Password" type="password" />
          <p className="error-message">{errors.password?.message}</p>
          <input className="login-custom-input" {...register("confirmPassword")} placeholder="Confirm Password" type="password" />
          <p className="error-message">{errors.confirmPassword?.message}</p>
          <input type="submit" value="Signup" className="button-name" />
        </form>
        <div className="login-link">
          <span className='new-user'>Already have an account? </span>
          <button onClick={() => navigate('/')} className="link-button">Login</button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
