import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const Overview = () => {
  const [overviewUrl, setOverviewUrl] = useState(localStorage.getItem('overviewUrl') || '');

  useEffect(() => {
    const fetchOverviewData = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (token && userId) {
        try {
          const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
          const result = await getApi(url, token);

          const fetchedUrl = result.data[0].attributes.overviewUrl;
          setOverviewUrl(fetchedUrl);
          localStorage.setItem('overviewUrl', fetchedUrl);
        } catch (error) {
          console.error('Error fetching overview data:', error);
        }
      }
    };

    if (!overviewUrl) {
      fetchOverviewData();
    }
  }, [overviewUrl]);


  return (
    <>
      {overviewUrl ? (
        <iframe
          title="Overview"
          src={overviewUrl}
          allowFullScreen
          className='custom-iframe'
        ></iframe>
      ) : (
        <p>Loading overview data...</p>
      )}
      <div className='custom-hide'>
        {''}
      </div>
    </>
  );
};

export default Overview;
