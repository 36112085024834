import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import './Login.css';
import { postApi } from "../../utils/postApiMethod";
import logo from "../../Components/Assests/rc-logo.png";

const schema = yup
  .object({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const payload = {
      identifier: data.username,
      password: data.password,
    };
    const url = "https://admin.rightc.fi/api/auth/local"
    try {
      const response = await postApi(url, payload);
      if (response?.jwt) {
        localStorage.setItem('token', response.jwt);
        localStorage.setItem('userId', response.user.id);
        localStorage.setItem('username', response.user.username);
        navigate('/home/overview');
      }
    } catch (error) {
      console.error("Login failed:", error.message);
      alert(error.message);
    }
  };

  return (
    <div className="container">
      <div className="login-signup-container">
        <div className="flex justify-center mb-6">
          <img src={logo} alt="Company Logo" className="com-logo" />
        </div>
        <div className="form-title">Sign In</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input className="login-custom-input" {...register("username")} placeholder="username" />
          <p className="error-message">{errors.username?.message}</p>
          <input
            className="login-custom-input"
            type="password"
            {...register("password")}
            placeholder="password"
          />
          <p className="error-message">{errors.password?.message}</p>
          <input type="submit" value="Sign In" className="button-name" />
        </form>
      </div>
    </div>
  );
};

export default Login;
