import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const Sales = () => {
    const [salesUrl, setSalesUrl] = useState(localStorage.getItem('salesUrl') || '');

    useEffect(() => {
        const fetchSalesData = async () => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');

            if (token && userId) {
                try {
                    const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
                    const result = await getApi(url, token);

                    const fetchedUrl = result.data[0].attributes.salesUrl;
                    setSalesUrl(fetchedUrl);
                    localStorage.setItem('salesUrl', fetchedUrl);
                } catch (error) {
                    console.error('Error fetching overview data:', error);
                }
            }
        };

        if (!salesUrl) {
            fetchSalesData();
        }
    }, [salesUrl]);


    return (
        <>
            {salesUrl ? (
                <iframe
                    title="Sales"
                    src={salesUrl}
                    allowFullScreen
                    className='custom-iframe'
                ></iframe>
            ) : (
                <p>Loading Sales data...</p>
            )}
            <div className='custom-hide'>
                {''}
            </div>
        </>
    );
};

export default Sales;
