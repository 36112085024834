export const getApi = async (url, token) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error?.message || "Failed to fetch data");
    }

    return await response.json();
  } catch (error) {
    console.error("Error in getApi:", error);
    throw error;
  }
};
