import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const Table = () => {
    const [tableUrl, setTableUrl] = useState(localStorage.getItem('tableUrl') || '');

    useEffect(() => {
        const fetchTableData = async () => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');

            if (token && userId) {
                try {
                    const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
                    const result = await getApi(url, token);

                    const fetchedUrl = result.data[0].attributes.tableUrl;
                    setTableUrl(fetchedUrl);
                    localStorage.setItem('tableUrl', fetchedUrl);
                } catch (error) {
                    console.error('Error fetching overview data:', error);
                }
            }
        };

        if (!tableUrl) {
            fetchTableData();
        }
    }, [tableUrl]);


    return (
        <>
            {tableUrl ? (
                <iframe
                    title="Table"
                    src={tableUrl}
                    allowFullScreen
                    className='custom-iframe'
                ></iframe>
            ) : (
                <p>Loading Table data...</p>
            )}
            <div className='custom-hide'>
                {''}
            </div>
        </>
    );
};

export default Table;
