import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const GrossProfit = () => {
  const [grossprofiteUrl, setGrossprofiteUrl] = useState(localStorage.getItem('grossprofiteUrl') || '');

  useEffect(() => {
    const fetchGrossprofiteData = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (token && userId) {
        try {
          const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
          const result = await getApi(url, token);

          const fetchedUrl = result.data[0].attributes.grossprofiteUrl;
          setGrossprofiteUrl(fetchedUrl);
          localStorage.setItem('grossprofiteUrl', fetchedUrl);
        } catch (error) {
          console.error('Error fetching overview data:', error);
        }
      }
    };

    if (!grossprofiteUrl) {
      fetchGrossprofiteData();
    }
  }, [grossprofiteUrl]);


  return (
    <>
      {grossprofiteUrl ? (
        <iframe
          title="GrossProfit"
          src={grossprofiteUrl}
          allowFullScreen
          className='custom-iframe'
        ></iframe>
      ) : (
        <p>Loading GrossProfit data...</p>
      )}
      <div className='custom-hide'>
        {''}
      </div>
    </>
  );
};

export default GrossProfit;
