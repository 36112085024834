import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const Air = () => {
    const [airUrl, setAirUrl] = useState(localStorage.getItem('airUrl') || '');

    useEffect(() => {
        const fetchAirData = async () => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');

            if (token && userId) {
                try {
                    const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
                    const result = await getApi(url, token);

                    const fetchedUrl = result.data[0].attributes.airUrl;
                    setAirUrl(fetchedUrl);
                    localStorage.setItem('airUrl', fetchedUrl);
                } catch (error) {
                    console.error('Error fetching overview data:', error);
                }
            }
        };

        if (!airUrl) {
            fetchAirData();
        }
    }, [airUrl]);


    return (
        <>
            {airUrl ? (
                <iframe
                    title="Air"
                    src={airUrl}
                    allowFullScreen
                    className='custom-iframe'
                ></iframe>
            ) : (
                <p>Loading Air data...</p>
            )}
            <div className='custom-hide'>
                {''}
            </div>
        </>
    );
};

export default Air;
