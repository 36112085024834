import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const SeaLCL = () => {
    const [sealclUrl, setSealclUrl] = useState(localStorage.getItem('sealclUrl') || '');

    useEffect(() => {
        const fetchSealclData = async () => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');

            if (token && userId) {
                try {
                    const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
                    const result = await getApi(url, token);

                    const fetchedUrl = result.data[0].attributes.sealclUrl;
                    setSealclUrl(fetchedUrl);
                    localStorage.setItem('sealclUrl', fetchedUrl);
                } catch (error) {
                    console.error('Error fetching overview data:', error);
                }
            }
        };

        if (!sealclUrl) {
            fetchSealclData();
        }
    }, [sealclUrl]);


    return (
        <>
            {sealclUrl ? (
                <iframe
                    title="SeaLCL"
                    src={sealclUrl}
                    allowFullScreen
                    className='custom-iframe'
                ></iframe>
            ) : (
                <p>Loading SeaLCL data...</p>
            )}
            <div className='custom-hide'>
                {''}
            </div>
        </>
    );
};

export default SeaLCL;
