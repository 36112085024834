export const postApi = async (url, payload) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const result = await response.json();

    if (response.ok) {
      // Return the successful result (with JWT and user details)
      return result;
    } else {
      // Throw an error with the message from the response
      throw new Error(result.error?.message || "Something went wrong");
    }
  } catch (error) {
    // Catch network or other errors
    throw new Error(error.message || "Network error");
  }
};
