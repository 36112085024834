import React, { useEffect, useState } from 'react';
import '../../App.css';
import { getApi } from '../../utils/getApiMethod';

const Shipments = () => {
  const [shipmentsUrl, setShipmentsUrl] = useState(localStorage.getItem('shipmentsUrl') || '');

  useEffect(() => {
    const fetchShipmentsData = async () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      if (token && userId) {
        try {
          const url = `https://admin.rightc.fi/api/urls/?filters[userid][%24eq]=${userId}`;
          const result = await getApi(url, token);

          const fetchedUrl = result.data[0].attributes.shipmentsUrl;
          setShipmentsUrl(fetchedUrl);
          localStorage.setItem('shipmentsUrl', fetchedUrl);
        } catch (error) {
          console.error('Error fetching overview data:', error);
        }
      }
    };

    if (!shipmentsUrl) {
      fetchShipmentsData();
    }
  }, [shipmentsUrl]);


  return (
    <>
      {shipmentsUrl ? (
        <iframe
          title="Shipments"
          src={shipmentsUrl}
          allowFullScreen
          className='custom-iframe'
        ></iframe>
      ) : (
        <p>Loading Shipments data...</p>
      )}
      <div className='custom-hide'>
        {''}
      </div>
    </>
  );
};

export default Shipments;
